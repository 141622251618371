import React, { Fragment, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getHomePage } from "../../actions/homePageActions";
import moment from "moment";

const Home = () => {
  const dispatch = useDispatch();
  const { homePageData } = useSelector((state) => ({
    homePageData: state.homePageData,
  }));
  useEffect(() => {
    const fetchData = () => {
      dispatch(getHomePage());
    };
    fetchData();
  }, [dispatch]);

  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" };
  };

  return (
    <Fragment>
      {homePageData.allNews && (
        <section className="news-section">
          <Container className="position-relative">
            <Row>
              <Col md="12">
                <div className="main-title">
                  <h2>Press</h2>
                </div>
              </Col>
            </Row>
            <Row>
              {homePageData.allNews &&
                homePageData.allNews.map((item, key) => (
                  <Col md="4" key={key}>
                    <a
                      href="#!"
                      onClick={(event) => {
                        event.preventDefault();
                        window.open(item.url);
                      }}
                    >
                      <div className="single-news-card">
                        <div className="news-card-img">
                          <img src={item.image} alt="news" />
                        </div>
                        <div className="news-card-content">
                          <div className="news-date">
                            {moment(item.created_at).format(
                              "dddd, MMMM Do YYYY, h:mm:ss a"
                            )}
                          </div>
                          <h4>
                            <div
                              dangerouslySetInnerHTML={prepareHtml(
                                item.description
                              )}
                            ></div>
                          </h4>
                        </div>
                      </div>
                    </a>
                  </Col>
                ))}
            </Row>
          </Container>
        </section>
      )}
    </Fragment>
  );
};

export default Home;
