import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, Button, Row, Col } from "react-bootstrap";
import { LOGO_SORT } from "../../assets/images";
import { loadDialogData } from "../../actions/baseActions";
import { useHistory } from "react-router-dom";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import Delete from "../../Component/Delete";
import { deleteFromPlayList } from "../../actions/homePageActions";

const PlayList = () => {
  const { push } = useHistory();
  const [music, setMusic] = useState({});
  const [isMusic, setIsMusic] = useState(false);
  const [playSong, setPlaySong] = useState(0);
  const [dialog, setDialog] = useState({});
  const dispatch = useDispatch();
  const { playListItem, dialogOpen, isAuth } = useSelector((state) => ({
    playListItem: state.playListItem,
    dialogOpen: state.dialogOpen,
    isAuth: state.isAuth,
  }));

  const handleShow = () => {
    push("/playlist");
  };

  useEffect(() => {
    if (!isAuth) {
      push("/login");
    }
  }, [isAuth, push]);

  const playListAction = (e, data, key) => {
    e.preventDefault();
    setMusic(data);
    setIsMusic(true);
    setPlaySong(key);
  };

  const deletePlayListAction = (e, data) => {
    e.preventDefault();
    data.message = "Are you sure, you want to remove ";
    data.dialogTitle = "Remove From PlayList";
    data.open = true;
    setDialog(data);
    dispatch(loadDialogData(true));
  };

  const handleDeleteClick = (data) => {
    const request = {};
    request.playlist_id = playListItem._id;
    request.music_id = data._id;
    dispatch(deleteFromPlayList(request));
  };

  const closePlayer = () => {
    setMusic({});
    setIsMusic(false);
  };

  const tapDetails = () => {
    const url = `/audio/details/${music._id}`;
    window.open(url, "_blank");
  };

  const musicEndAction = () => {
    const nextData = playSong + 1;
    if (playListItem.item.length > nextData) {
      const data = playListItem.item[nextData];
      if (data) {
        setMusic(data);
        setIsMusic(true);
        setPlaySong(nextData);
      } else {
        setIsMusic(false);
        setPlaySong(0);
      }
    } else {
      setIsMusic(false);
      setPlaySong(0);
    }
  };

  return (
    <Fragment>
      <div>
        <div className="create-new-playlist">
          <Button className="float-start" onClick={handleShow}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.875 7.5H8.5V5.125C8.5 5.05625 8.44375 5 8.375 5H7.625C7.55625 5 7.5 5.05625 7.5 5.125V7.5H5.125C5.05625 7.5 5 7.55625 5 7.625V8.375C5 8.44375 5.05625 8.5 5.125 8.5H7.5V10.875C7.5 10.9437 7.55625 11 7.625 11H8.375C8.44375 11 8.5 10.9437 8.5 10.875V8.5H10.875C10.9437 8.5 11 8.44375 11 8.375V7.625C11 7.55625 10.9437 7.5 10.875 7.5Z"
                fill="white"
              />
              <path
                d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM8 13.8125C4.79063 13.8125 2.1875 11.2094 2.1875 8C2.1875 4.79063 4.79063 2.1875 8 2.1875C11.2094 2.1875 13.8125 4.79063 13.8125 8C13.8125 11.2094 11.2094 13.8125 8 13.8125Z"
                fill="white"
              />
            </svg>{" "}
            Playlist
          </Button>
        </div>
      </div>
      <div className="my-playlist-section">
        {playListItem && (
          <>
            <div className="playlist-profile mb-4">
              <div className="playlist-profile-img">
                {playListItem.item.length > 0 ? (
                  <img
                    src={playListItem.item[0].image_thumb_url}
                    alt="playlist_logo"
                  />
                ) : (
                  <img src={LOGO_SORT} alt="playlist_logo" />
                )}
              </div>
              <div className="playlist-profile-info">
                <h3>{playListItem.title}</h3>
                <p>
                  Just Updated · <span>{playListItem.item.length} Songs</span>
                </p>
              </div>
            </div>
            <div className="playlist-songs-list">
              {playListItem.item.length > 0 &&
                playListItem.item.map((listItem, key) => (
                  <div className="playlist-single-song" key={key}>
                    <div
                      className="playlist-song-img"
                      onClick={(e) => playListAction(e, listItem, key)}
                    >
                      <img src={listItem.image_thumb_url} alt="music_logo" />
                    </div>
                    <div className="playlist-single-info">
                      <div
                        className="song-album-info"
                        onClick={(e) => playListAction(e, listItem, key)}
                      >
                        <div className="song-name">&nbsp;</div>
                        <div className="artist-overview song-name">
                          {listItem.title}
                        </div>
                      </div>
                      <div className="time-duration">&nbsp;</div>
                      <div className="more-options">
                        <Dropdown className="common-dropdown">
                          <Dropdown.Toggle variant="link">
                            <span>
                              <svg
                                width="40"
                                height="11"
                                viewBox="0 0 40 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M29.75 5.25C29.75 3.92392 30.2768 2.65215 31.2145 1.71447C32.1521 0.776784 33.4239 0.25 34.75 0.25C36.0761 0.25 37.3479 0.776784 38.2855 1.71447C39.2232 2.65215 39.75 3.92392 39.75 5.25C39.75 6.57608 39.2232 7.84785 38.2855 8.78553C37.3479 9.72322 36.0761 10.25 34.75 10.25C33.4239 10.25 32.1521 9.72322 31.2145 8.78553C30.2768 7.84785 29.75 6.57608 29.75 5.25ZM15.2045 5.25C15.2045 3.92392 15.7313 2.65215 16.669 1.71446C17.6067 0.776783 18.8785 0.249999 20.2045 0.249999C21.5306 0.249999 22.8024 0.776783 23.7401 1.71447C24.6778 2.65215 25.2045 3.92392 25.2045 5.25C25.2045 6.57608 24.6778 7.84785 23.7401 8.78553C22.8024 9.72322 21.5306 10.25 20.2045 10.25C18.8785 10.25 17.6067 9.72322 16.669 8.78553C15.7313 7.84785 15.2045 6.57608 15.2045 5.25ZM5.65909 0.249999C4.33301 0.249998 3.06124 0.776782 2.12356 1.71446C1.18588 2.65215 0.659092 3.92392 0.659092 5.25C0.659092 6.57608 1.18588 7.84785 2.12356 8.78553C3.06124 9.72321 4.33301 10.25 5.65909 10.25C6.98517 10.25 8.25694 9.72322 9.19463 8.78553C10.1323 7.84785 10.6591 6.57608 10.6591 5.25C10.6591 3.92392 10.1323 2.65215 9.19463 1.71446C8.25694 0.776783 6.98517 0.249999 5.65909 0.249999V0.249999Z"
                                  fill="white"
                                />
                              </svg>
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              href="#!"
                              onClick={(e) => playListAction(e, listItem, key)}
                            >
                              Play
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#!"
                              onClick={(e) => deletePlayListAction(e, listItem)}
                            >
                              Remove from Playlist
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
      {isMusic && music._id && (
        <Row>
          <Col md={12}>
            <div className="storyAudioPlayer pt-2 playlist_music">
              <div className="story-preview">
                <div className="music-icon">
                  <img src={music.image_thumb_url} alt={`${music.title}`} />
                </div>
                <div className="sticky-player-title">
                  <Button
                    className="btn btn-link main-btn"
                    onClick={() => tapDetails()}
                  >
                    Tap To Details
                  </Button>
                </div>
                <div className="AudioPlayer">
                  <AudioPlayer
                    volume={0.1}
                    autoPlay
                    src={music.radio_url}
                    onEnded={() => musicEndAction()}
                  />
                </div>
                <Button
                  className="player-close-btn main-btn"
                  onClick={() => closePlayer()}
                >
                  Close
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      )}
      {dialogOpen && (
        <Delete dialog={dialog} handleDeleteClick={handleDeleteClick} />
      )}
    </Fragment>
  );
};

export default PlayList;
