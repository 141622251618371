import React, { Fragment, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getHomePageSearch } from "../../actions/homePageActions";
import { useSelector, useDispatch } from "react-redux";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const Home = () => {
  var HeroSlider = {
    dots: true,
    arrows: false,
    infinite: false,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  var AudioBookSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    autoplay: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const search = useLocation().search;
  const dispatch = useDispatch();
  const history = useHistory();
  const { homePageData, kids_safe } = useSelector((state) => ({
    homePageData: state.homePageData,
    kids_safe: state.kids_safe,
  }));
  const { type } = useParams();

  useEffect(() => {
    const fetchData = () => {
      const keywordValue = new URLSearchParams(search).get("keyword");
      var request = {};
      if (type === "audio-search") {
        request.type = "AUDIO";
        request.kids_safe = kids_safe;
      }
      if (type === "video-search") request.type = "VIDEO";
      if (type === "web-search") request.type = "WEB";
      request.keyword = keywordValue;
      dispatch(getHomePageSearch(request));
    };
    fetchData();
  }, [dispatch, type, search, kids_safe]);

  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" };
  };

  const openPage = (e, item) => {
    e.preventDefault();
    history.push(`/audio/details/${item._id}`);
  };

  return (
    <Fragment>
      {homePageData?.bannerData && (
        <Slider {...HeroSlider} className="hero-slider">
          {homePageData.bannerData &&
            homePageData.bannerData.map((item, key) => (
              <div className="hero-slide" key={key}>
                <img src={item.image_thumb_url} alt={item.title} />
              </div>
            ))}
        </Slider>
      )}

      {type === "audio-search" ? (
        <>
          {homePageData?.item &&
            homePageData.item.map((item, key) => (
              <div className="audiobook-single-category" key={key}>
                <div className="audiobook-category-title">
                  <h2>{item.title}</h2>
                  <Link to={`/audio/category/${item._id}`}>View All</Link>
                </div>
                <Slider {...AudioBookSlider} className="audiobook-slider">
                  {item.items &&
                    item.items.map((videoItem, key) => (
                      <div className="audiobook-slide" key={key}>
                        <div className="audiobook-card">
                          <a
                            href={`/#!`}
                            className="audiobook-image"
                            onClick={(e) => openPage(e, videoItem)}
                          >
                            <img
                              src={videoItem.image_thumb_url}
                              alt={videoItem.title}
                            />
                          </a>
                          <div className="audiobook-content">
                            <a
                              href={`#!`}
                              onClick={(e) => openPage(e, videoItem)}
                            >
                              {videoItem.title}
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            ))}
        </>
      ) : (
        <div className="audiobook-single-category">
          <div className="audiobook-slider">
            <Row>
              {homePageData?.data &&
                homePageData.data.map((videoItem, key) => (
                  <Col md={3} key={key}>
                    <div className="audiobook-slide" key={key}>
                      <div className="audiobook-card">
                        <a
                          href={`/#!`}
                          className="audiobook-image"
                          onClick={(e) => openPage(e, videoItem)}
                        >
                          <img
                            src={videoItem.image_thumb_url}
                            alt={videoItem.title}
                          />
                        </a>
                        <div className="audiobook-content">
                          <a
                            href={`#!`}
                            onClick={(e) => openPage(e, videoItem)}
                          >
                            {videoItem.title}
                          </a>

                          <h6>
                            <div
                              dangerouslySetInnerHTML={prepareHtml(
                                videoItem.description
                              )}
                            ></div>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
            </Row>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Home;
