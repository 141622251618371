import React, { lazy, Suspense, Fragment } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { RoutesPage, Routes, AuthPage } from "../Route/route";
import Spinner from "../Component/Spinner";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Header = lazy(() => import("./Partial/Header"));
const Footer = lazy(() => import("./Partial/Footer"));
const SideBar = lazy(() => import("./Partial/SideBar"));

const RouteWithHeader = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Fragment>
          <div className="App">
            <Header />
            <div className="main-wrapper">
              <SideBar />
              <div className="main-content">
                <Component {...props} title={rest.title} />
                <Footer />
              </div>
            </div>
          </div>
        </Fragment>
      )}
    />
  );
};

const RouteWithOutHeader = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Fragment>
          <Component {...props} title={rest.title} />
        </Fragment>
      )}
    />
  );
};

const RouteAuth = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Fragment>
          <div className="App">
            <Header />
            <div className="main-wrapper">
              {/* <SideBar /> */}
              <div className="main-content">
                <Component {...props} title={rest.title} />
              </div>
            </div>
          </div>
        </Fragment>
      )}
    />
  );
};

const Index = (props) => {
  return (
    <Fragment>
      <Suspense fallback={<Spinner />}>
        <Switch>
          {AuthPage.map((route, index) => (
            <RouteAuth
              key={index}
              exact
              path={route.path}
              component={route.component}
              title={route.title}
            />
          ))}
          {Routes.map((route, index) => (
            <RouteWithOutHeader
              key={index}
              exact
              path={route.path}
              component={route.component}
              title={route.title}
            />
          ))}
          {RoutesPage.map((route, index) => (
            <RouteWithHeader
              key={index}
              exact
              path={route.path}
              component={route.component}
              title={route.title}
            />
          ))}

          <Redirect to="/404" />
        </Switch>
      </Suspense>
    </Fragment>
  );
};

export default Index;
