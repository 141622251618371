import * as types from "./actionsTypes";
import submittingRequestStatus from "./submittingRequestStatusAction";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import * as API from "../utils/apiPath";
import { agent } from "../utils/agent";
import { setToaster } from "../utils/helpers";

export function ParamsDataSuccess(userParams) {
  return { type: types.LOADED_USER_PARAM_SUCCESS, userParams };
}

export function loginDataSuccess(userInfo) {
  return { type: types.LOADED_USER_INFO_SUCCESS, userInfo };
}

export function loadUserAuth(isAuth) {
  return { type: types.LOADED_USER_AUTH_SUCCESS, isAuth };
}

export function loadKidsMood(kids_safe) {
  return { type: types.KIDS_SAFE, kids_safe };
}

export function userLoginData(params, push) {
  return async (dispatch) => {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.LOGIN, params)
      .then((response) => {
        setToaster(response.data.message);
        dispatch(submittingRequestStatus(false));
        dispatch(loginDataSuccess(response.data.data));
        dispatch(loadUserAuth(true));
        localStorage.setItem("userToken", response.data.data.api_token);
        const userData = response.data.data;
        if (userData && userData.age) {
          dispatch(loadKidsMood(userData.age < 15 ? true : false));
          push("/");
        }
      })
      .catch((error) => {
        setToaster(error.message);
        dispatch(submittingRequestStatus(false));
      });
  };
}

export function userLogout() {
  return (dispatch) => {
    dispatch(loadUserAuth(false));
    dispatch(loginDataSuccess({}));
    dispatch(loadKidsMood(false));
    localStorage.removeItem("userToken");
  };
}

export function updateKidsMood(value) {
  return async (dispatch) => {
    dispatch(loadKidsMood(value));
    //window.location.reload();
  };
}

export function resendOtp(params) {
  return async (dispatch) => {
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .post(API.SEND_OTP, params)
      .then((response) => {
        dispatch(toggleNetworkRequestStatus(false));
        setToaster(response.data.message);
        const data = response.data.data;
        data.type = params.type;
        dispatch(ParamsDataSuccess(data));
      })
      .catch((error) => {
        setToaster(error.message);
        dispatch(toggleNetworkRequestStatus(false));
      });
  };
}

export function checkOtp(params, push) {
  return async (dispatch) => {
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .post(API.CHECK_OTP, params)
      .then((response) => {
        setToaster(response.data.message);
        dispatch(toggleNetworkRequestStatus(false));
        dispatch(loginDataSuccess(response.data.data));
        dispatch(loadUserAuth(true));
        localStorage.setItem("userToken", response.data.data.api_token);
        const userData = response.data.data;
        if (userData && userData.age) {
          dispatch(loadKidsMood(userData.age < 15 ? true : false));
        }
        dispatch(ParamsDataSuccess({}));
        if (params.type === "REGISTER") {
          push("/my-mood");
        } else {
          push("/");
        }
      })
      .catch((error) => {
        setToaster(error.message);
        dispatch(toggleNetworkRequestStatus(false));
      });
  };
}

export function forgotPassword(params, push) {
  return async (dispatch) => {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.FORGOT_PASSWORD, params)
      .then((response) => {
        setToaster(response.data.message);
        dispatch(submittingRequestStatus(false));
        const data = response.data.data;
        data.type = "AUTH";
        dispatch(ParamsDataSuccess(data));
        push("/reset-password");
      })
      .catch((error) => {
        setToaster(error.message);
        dispatch(submittingRequestStatus(false));
      });
  };
}

export function updatePasswordOtp(params, push) {
  return async (dispatch) => {
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .post(API.UPDATE_PASSWORD_OTP, params)
      .then((response) => {
        setToaster(response.data.message);
        dispatch(toggleNetworkRequestStatus(false));
        dispatch(ParamsDataSuccess({}));
        push("/login");
      })
      .catch((error) => {
        setToaster(error.message);
        dispatch(toggleNetworkRequestStatus(false));
      });
  };
}

export function resetPassword(params, push) {
  return async (dispatch) => {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.RESET_PASSWORD, params)
      .then((response) => {
        setToaster(response.data.message);
        dispatch(submittingRequestStatus(false));
        dispatch(loginDataSuccess({}));
        push("/");
      })
      .catch((error) => {
        setToaster(error.message);
        dispatch(submittingRequestStatus(false));
      });
  };
}

export const updateProfileData = (params) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.post(API.UPDATE_PROFILE, params);
    dispatch(toggleNetworkRequestStatus(false));
    setToaster(response.data.message);
    dispatch(loginDataSuccess(response.data.data));
    const userData = response.data.data;
    if (userData && userData.age) {
      dispatch(loadKidsMood(userData.age < 15 ? true : false));
      window.location.reload();
    }
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    setToaster(error.message);
  }
};

export const updatePassword = (params) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.put(API.UPDATE_PASSWORD, params);
    dispatch(toggleNetworkRequestStatus(false));
    setToaster(response.data.message);
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    setToaster(error.message);
  }
};

export function userRegister(params, push) {
  return async (dispatch) => {
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .post(API.AUTH_REGISTER, params)
      .then((response) => {
        setToaster(response.data.message);
        dispatch(toggleNetworkRequestStatus(false));
        const data = response.data.data;
        data.type = "REGISTER";
        dispatch(ParamsDataSuccess(data));
        push("/otp");
      })
      .catch((error) => {
        setToaster(error.message);
        dispatch(toggleNetworkRequestStatus(false));
      });
  };
}

export function loginWithMobile(params, push) {
  return async (dispatch) => {
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .post(API.AUTH_LOGIN_MOBILE, params)
      .then((response) => {
        setToaster(response.data.message);
        dispatch(toggleNetworkRequestStatus(false));
        const data = response.data.data;
        data.type = "AUTH";
        dispatch(ParamsDataSuccess(data));
        push("/otp");
      })
      .catch((error) => {
        setToaster(error.message);
        dispatch(toggleNetworkRequestStatus(false));
      });
  };
}

export const updateMood = (params, push) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.post(API.UPDATE_MOOD, params);
    dispatch(toggleNetworkRequestStatus(false));
    setToaster(response.data.message);
    dispatch(loginDataSuccess(response.data.data));
    push("/");
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    setToaster(error.message);
  }
};
