export const SUBMITTING_REQUEST_STATUS = "SUBMITTING_REQUEST_STATUS";
export const LOADED_USER_AUTH_SUCCESS = "LOADED_USER_AUTH_SUCCESS";
export const LOADED_USER_PARAM_SUCCESS = "LOADED_USER_PARAM_SUCCESS";
export const LOADED_USER_INFO_SUCCESS = "LOADED_USER_INFO_SUCCESS";
export const NETWORK_REQUEST_STATUS = "NETWORK_REQUEST_STATUS";
export const LOADED_SETTING_INFO_SUCCESS = "LOADED_SETTING_INFO_SUCCESS";
export const LOAD_HOME_PAGE_DATA_SUCCESS = "LOAD_HOME_PAGE_DATA_SUCCESS";
export const LOAD_PAGE_DATA_SUCCESS = "LOAD_PAGE_DATA_SUCCESS";
export const LOAD_FAQ_DATA_SUCCESS = "LOAD_FAQ_DATA_SUCCESS";
export const LOADED_TOASTER_INFO_SUCCESS = "LOADED_TOASTER_INFO_SUCCESS";
export const LOADED_DATA_SUCCESS = "LOADED_DATA_SUCCESS";
export const LOAD_PAGINATION_DATA_SUCCESS = "LOAD_PAGINATION_DATA_SUCCESS";
export const LOADED_NOTIFICATION_COUNT_SUCCESS =
  "LOADED_NOTIFICATION_COUNT_SUCCESS";
export const LOADED_USER_NOTIFICATION_DATA_SUCCESS =
  "LOADED_USER_NOTIFICATION_DATA_SUCCESS";
export const LOADED_HUM_BURGER_SUCCESS = "LOADED_HUM_BURGER_SUCCESS";
export const LOADED_CATEGORY_DATA_SUCCESS = "LOADED_CATEGORY_DATA_SUCCESS";
export const KIDS_SAFE = "KIDS_SAFE";
export const LOADED_PLAYLIST_DATA_SUCCESS = "LOADED_PLAYLIST_DATA_SUCCESS";
export const LOADED_DIALOG_OPEN_SUCCESS = "LOADED_DIALOG_OPEN_SUCCESS";
export const LOADED_PLAYLIST_ITEM_SUCCESS = "LOADED_PLAYLIST_ITEM_SUCCESS";
export const LOAD_MOOD_DATA_SUCCESS = "LOAD_MOOD_DATA_SUCCESS";
export const LOAD_BANNER_DATA_SUCCESS = "LOAD_BANNER_DATA_SUCCESS";
