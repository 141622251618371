import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { updateProfileData } from "../../actions/userActions";
import { Row, Button, Col, Form } from "react-bootstrap";
import { LOGIN_BG } from "../../assets/images";
import { useHistory } from "react-router-dom";

const UpdateProfile = () => {
  const { isAuth, userInfo } = useSelector((state) => ({
    isAuth: state.isAuth,
    userInfo: state.userInfo,
  }));
  const { register, errors, handleSubmit } = useForm({
    defaultValues: userInfo,
  });
  const [selected, setSelected] = useState(
    userInfo.gender ? userInfo.gender : "Male"
  );
  const dispatch = useDispatch();
  const { push } = useHistory();

  useEffect(() => {
    if (!isAuth) {
      push("/login");
    }
  }, [isAuth, push]);

  const onSubmit = (data) => {
    data.gender = selected;
    dispatch(updateProfileData(data, push));
  };

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  return (
    <Fragment>
      <div className="create-account-section update-profile-section">
        <img className="create-account-bg" src={LOGIN_BG} alt="logo_bg" />
        <div className="createaccount-content">
          <div className="createaccount-form">
            <Form
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
              className="d-flex justify-content-between flex-column"
            >
              <div className="account-form-content">
                <h2>Update Profile</h2>
                <p className="info">&nbsp;</p>

                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="CurrentPassword">
                      <Form.Label>First Name*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        defaultValue={userInfo.first_name}
                        name="name"
                        ref={register({
                          required: true,
                        })}
                      />
                      {errors.name && errors.name.type === "required" && (
                        <span role="alert" className="color">
                          Please enter your name.
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Label>Mobile Number*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Mobile Number*"
                      name="mobile_number"
                      ref={register({
                        required: true,
                        minLength: 10,
                        maxLength: 10,
                        pattern: {
                          value: /^(\+\d{1,3}[- ]?)?\d{10}$/i,
                        },
                      })}
                    />
                    {errors.mobile_number &&
                      errors.mobile_number.type === "required" && (
                        <span role="alert" className="color">
                          Please enter your mobile number.
                        </span>
                      )}
                    {errors.mobile_number &&
                      errors.mobile_number.type === "maxLength" && (
                        <span role="alert" className="color">
                          Your mobile number should not exceed 10 digits.
                        </span>
                      )}
                    {errors.mobile_number &&
                      errors.mobile_number.type === "minLength" && (
                        <span role="alert" className="color">
                          Your mobile number should contain atleast 10 digits.
                        </span>
                      )}
                    {errors.mobile_number &&
                      errors.mobile_number.type === "pattern" && (
                        <span role="alert" className="color">
                          Please enter correct mobile number. It should be
                          entered without country code.( Example xxxxxxxxxx)
                        </span>
                      )}
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="PhoneNumber">
                      <Form.Label>Email Address*</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter Your Email ID"
                        name="email"
                        ref={register({
                          required: true,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          },
                          maxLength: 50,
                        })}
                      />
                      {errors.email && errors.email.type === "required" && (
                        <span role="alert" className="color">
                          Please enter your email address.
                        </span>
                      )}
                      {errors.email && errors.email.type === "pattern" && (
                        <span role="alert" className="color">
                          Invalid email address.
                        </span>
                      )}
                      {errors.email && errors.email.type === "maxLength" && (
                        <span role="alert" className="color">
                          Your email should not exceed 50 characters.
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="Birthday">
                      <Form.Label>dob Of Birth*</Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="Enter Your Date Of Birth"
                        name="dob"
                        ref={register({
                          required: true,
                        })}
                        readOnly={true}
                      />
                      {errors.dob && errors.dob.type === "required" && (
                        <span role="alert" className="color">
                          Please enter your date of birth.
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Label>Gender</Form.Label>
                    {["radio"].map((type) => (
                      <div
                        key={`default-${type}`}
                        className="w-100 d-inline-flex justify-content-between mb-3"
                      >
                        <Form.Check // prettier-ignore
                          className="d-inline-block"
                          type={type}
                          name={"gender"}
                          label={`Male`}
                          id={`male`}
                          checked={selected === "Male" ? true : false}
                          value="Male"
                          onChange={handleChange}
                        />

                        <Form.Check
                          className="d-inline-block"
                          type={type}
                          name={"gender"}
                          label={`Female`}
                          id={`female`}
                          value="Female"
                          checked={selected === "Female" ? true : false}
                          onChange={handleChange}
                        />
                        <Form.Check
                          className="d-inline-block"
                          type={type}
                          name={"gender"}
                          label={`Other`}
                          id={`other`}
                          value="Other"
                          checked={selected === "Other" ? true : false}
                          onChange={handleChange}
                        />
                      </div>
                    ))}
                  </Col>

                  <Col md={12} className="text-center mt-4">
                    <Button className="btn main-btn" type="submit">
                      Update
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UpdateProfile;
