import React, { Fragment, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { getHomePageCategory } from "../../actions/homePageActions";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";

const Home = () => {
  var HeroSlider = {
    dots: true,
    arrows: false,
    infinite: false,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const { category_id } = useParams();
  const { homePageData } = useSelector((state) => ({
    homePageData: state.homePageData,
  }));
  useEffect(() => {
    const fetchData = () => {
      var request = {};
      request.type = "WEB";
      request.category = category_id;
      dispatch(getHomePageCategory(request));
    };
    fetchData();
  }, [dispatch, category_id]);

  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" };
  };

  const openPage = (e, url) => {
    e.preventDefault();
    window.open(url, "_blank");
  };

  const openBanner = (item) => {
    if (item.type === "VIDEO") {
      history.push(`/video/${item.category}`);
    }
    if (item.type === "AUDIO") {
      history.push(`/audio/category/${item.category}`);
    }
    if (item.type === "WEB") {
      history.push(`/web/${item.category}`);
    }
  };

  return (
    <Fragment>
      {homePageData?.bannerData && (
        <Slider {...HeroSlider} className="hero-slider">
          {homePageData.bannerData &&
            homePageData.bannerData.map((item, key) => (
              <div
                className="hero-slide slide_click"
                key={key}
                onClick={(e) => openBanner(item)}
              >
                <img src={item.image_thumb_url} alt={item.title} />
              </div>
            ))}
        </Slider>
      )}
      <div className="audiobook-single-category">
        <div className="audiobook-slider">
          <Row>
            {homePageData?.data &&
              homePageData.data.map((videoItem, key) => (
                <Col md={3}>
                  <div className="audiobook-slide" key={key}>
                    <div className="audiobook-card">
                      <a
                        href={`/#!`}
                        className="audiobook-image"
                        onClick={(e) => openPage(e, videoItem)}
                      >
                        <img
                          src={videoItem.image_thumb_url}
                          alt={videoItem.title}
                        />
                      </a>
                      <div className="audiobook-content">
                        <a href={`#!`} onClick={(e) => openPage(e, videoItem)}>
                          {videoItem.title}
                        </a>

                        <h6>
                          <div
                            dangerouslySetInnerHTML={prepareHtml(
                              videoItem.description
                            )}
                          ></div>
                        </h6>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        </div>
      </div>
    </Fragment>
  );
};

export default Home;
