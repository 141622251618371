import React, { Fragment, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { getHomeMusic } from "../../actions/homePageActions";
import { useSelector, useDispatch } from "react-redux";

const Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { homePageData, kids_safe } = useSelector((state) => ({
    homePageData: state.homePageData,
    kids_safe: state.kids_safe,
  }));
  const { parent_id } = useParams();
  useEffect(() => {
    const fetchData = () => {
      var request = {};
      request.type = "AUDIO";
      request.kids_safe = kids_safe;
      request.category = parent_id;
      dispatch(getHomeMusic(request));
    };
    fetchData();
  }, [dispatch, parent_id, kids_safe]);

  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" };
  };

  const openPage = (e, item) => {
    e.preventDefault();
    history.push(`/audio/details/${item._id}`);
  };

  return (
    <Fragment>
      <div className="audiobook-single-category">
        <div className="audiobook-slider">
          <Row>
            {homePageData?.items &&
              homePageData.items.map((videoItem, key) => (
                <Col md={3} key={key}>
                  <div className="audiobook-slide" key={key}>
                    <div className="audiobook-card">
                      <a
                        href={`/#!`}
                        className="audiobook-image"
                        onClick={(e) => openPage(e, videoItem)}
                      >
                        <img
                          src={videoItem.image_thumb_url}
                          alt={videoItem.title}
                        />
                      </a>
                      <div className="audiobook-content">
                        <a href={`#!`} onClick={(e) => openPage(e, videoItem)}>
                          {videoItem.title}
                        </a>

                        <h6>
                          <div
                            dangerouslySetInnerHTML={prepareHtml(
                              videoItem.description
                            )}
                          ></div>
                        </h6>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        </div>
      </div>
    </Fragment>
  );
};

export default Home;
