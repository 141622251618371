import React, { Fragment } from "react";
import { LOGIN_BG } from "../../assets/images";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { userLoginData } from "../../actions/userActions";
import { useHistory } from "react-router-dom";

const Login = () => {
  const { register, errors, handleSubmit } = useForm();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    data.device_token = "";
    data.device_type = "WEB";
    dispatch(userLoginData(data, push));
  };

  return (
    <Fragment>
      <div className="create-account-section">
        <img className="create-account-bg" src={LOGIN_BG} alt="login_bg" />
        <div className="createaccount-content row gx-0">
          <div className="col-md-8 createaccount-form order-md-last">
            <Form
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
              className="d-flex justify-content-between flex-column"
            >
              <div className="account-form-content">
                <h2>Welcome to 1R-FM</h2>
                <p className="info">
                  Log in with your email address & password.
                </p>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email Address*</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Your Email ID"
                    name="email"
                    ref={register({
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      },
                      maxLength: 50,
                    })}
                  />
                  {errors.email && errors.email.type === "required" && (
                    <span role="alert" className="color">
                      Please enter your email address.
                    </span>
                  )}
                  {errors.email && errors.email.type === "pattern" && (
                    <span role="alert" className="color">
                      Invalid email address.
                    </span>
                  )}
                  {errors.email && errors.email.type === "maxLength" && (
                    <span role="alert" className="color">
                      Your email should not exceed 50 characters.
                    </span>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password*</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Enter Your Password"
                    ref={register({
                      required: true,
                    })}
                  />
                  {errors.password && errors.password.type === "required" && (
                    <span role="alert" className="color">
                      Please enter your password.
                    </span>
                  )}
                </Form.Group>

                <Button
                  className="btn main-btn"
                  variant="primary"
                  type="submit"
                >
                  Submit
                </Button>
              </div>

              <div className="donthaveaccount mt-5">
                <p className="mb-0">
                  Don't have a 1R-FM account yet?{" "}
                  <Link to="/signUp">Sign Up</Link> &{" "}
                  <Link to="/forgot-password">Forgot Password?</Link>
                </p>
              </div>
            </Form>
          </div>
          <div className="col-md-4 login-with-socialmedia-section order-md-first">
            <div className="login-with-socialmedia">
              <h2>Login with</h2>
              <p className="info">Login with social media platforms.</p>
              <ul className="p-0 m-0">
                <li>
                  <Link to="/mobile-login" className="twitter">
                    Sign In with Mobile Number
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
