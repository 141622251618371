import React, { useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadPageData } from "../../actions/homePageActions";
import { Container, Row, Col } from "react-bootstrap";
const Index = (props) => {
  const slug = "/tram-and-conditions";
  const dispatch = useDispatch();
  const { pageData } = useSelector((state) => ({
    pageData: state.pageData,
  }));

  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" };
  };

  useEffect(() => {
    const fetchData = () => {
      dispatch(loadPageData(slug));
    };
    fetchData();
  }, [dispatch, slug]);

  return (
    <Fragment>
      <section className="abhoutus-section light-blue-bg">
        <Container>
          <Row>
            <Col md="12">
              <div className="main-title">
                <h2>{pageData.title}</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="about-content">
                <div
                  className="about-page-text"
                  dangerouslySetInnerHTML={prepareHtml(pageData.content)}
                ></div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default Index;
