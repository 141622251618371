import React, { Fragment, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { LOGIN_BG } from "../../assets/images";
import { updatePassword } from "../../actions/userActions";

const ChangePassword = () => {
  const { isAuth } = useSelector((state) => ({
    isAuth: state.isAuth,
  }));
  const { register, errors, handleSubmit, watch } = useForm();
  const dispatch = useDispatch();
  const { push } = useHistory();

  useEffect(() => {
    if (!isAuth) {
      push("/login");
    }
  }, [isAuth, push]);

  const password = useRef({});
  password.current = watch("password", "");

  const onSubmit = (data) => {
    dispatch(updatePassword(data));
  };

  return (
    <Fragment>
      <div className="create-account-section change-password-section">
        <img className="create-account-bg" src={LOGIN_BG} alt="login_bg" />
        <div className="createaccount-content">
          <div className="createaccount-form">
            <Form
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
              className="d-flex justify-content-between flex-column"
            >
              <div className="account-form-content">
                <h2>Change Password</h2>
                <p className="info">&nbsp;</p>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="CurrentPassword">
                      <Form.Label>Current Password*</Form.Label>
                      <Form.Control
                        type="password"
                        name="current_password"
                        placeholder="Enter Current Password"
                        ref={register({
                          required: "Please enter current password.",
                        })}
                      />
                      {errors.current_password &&
                        errors.current_password.message && (
                          <span role="alert" className="color">
                            {errors.current_password.message}
                          </span>
                        )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="NewPassword">
                      <Form.Label>New Password*</Form.Label>
                      <Form.Control
                        placeholder="Enter New Password"
                        type="password"
                        name="password"
                        ref={register({
                          required: "Please enter new password.",
                          minLength: {
                            value: 6,
                            message:
                              "New Password should contain at least 6 characters.",
                          },
                          maxLength: {
                            value: 50,
                            message:
                              "New password should not exceed 50 characters.",
                          },
                          pattern: {
                            value:
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#/$%/^&/*])(?=.{8,})/,
                            message:
                              "Password should contain at least 1 Uppercase,1 Lowercase,1 Numeric and 1 special character.",
                          },
                        })}
                      />
                      {errors.password && errors.password.message && (
                        <span role="alert" className="color">
                          {errors.password.message}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="ConfirmNewPassword">
                      <Form.Label>Confirm Password*</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Confirm Password"
                        name="password_confirmation"
                        ref={register({
                          required: "Please enter confirm password.",
                          validate: (value) =>
                            value === password.current ||
                            "New password and Confirm password do not match.",
                        })}
                      />
                      {errors.password_confirmation &&
                        errors.password_confirmation.message && (
                          <span role="alert" className="color">
                            {errors.password_confirmation.message}
                          </span>
                        )}
                    </Form.Group>
                  </Col>
                  <Col md={12} className="text-center mt-4">
                    <Button className="btn main-btn" type="submit">
                      Update
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ChangePassword;
