import React from "react";
import { NavLink } from "react-router-dom";

import Breadcrumb from "../../Component/Breadcrumb";

const Index = (props) => {
  return (
    <>
      <Breadcrumb {...props} />
      {/* <section className="page-title green-bg">
        <Container>
          <div className="page-title-contant"></div>
        </Container>
      </section> */}
      {/* <section className="main-contant-wrapper">
        <div>
          <div className="error">
            <div className="error__title">404</div>
            <div className="error__description">Page Not Found...</div>
            <NavLink to="/" className="error__button error__button--active">
              Back TO Home
            </NavLink>
          </div>
        </div>
      </section> */}

      <section className="page-section">
        <div className="page-decoration-1"></div>
        <div className="page-decoration-2"></div>
        <div className="page-decoration-3"></div>
        <div className="full-width-screen">
          <div className="container-fluid">
            <div className="content-detail">
              <h1 className="global-title">
                <span>4</span>
                <span>0</span>
                <span>4</span>
              </h1>
              <div className="page-desc">
                <h4 className="sub-title">Oops!</h4>
                <p className="detail-text">
                  We're sorry, The page you were looking for doesn't exist
                  anymore.
                </p>
                <div className="back-btn">
                  <NavLink
                    to="/"
                    className="error__button error__button--active"
                  >
                    Back TO Home
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Index;
