import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  getPlaylist,
  createPlaylist,
  deletePlayList,
  playListItemAction,
} from "../../actions/homePageActions";
import { loadDialogData } from "../../actions/baseActions";
import { Dropdown, Button, Offcanvas, Form } from "react-bootstrap";
import { LOGO_SORT } from "../../assets/images";
import { useHistory } from "react-router-dom";
import Delete from "../../Component/Delete";

const PlayList = () => {
  const { playlistData, isAuth, dialogOpen } = useSelector((state) => ({
    playlistData: state.playlistData,
    isAuth: state.isAuth,
    dialogOpen: state.dialogOpen,
  }));
  const { register, errors, handleSubmit } = useForm();
  const [show, setShow] = useState(false);
  const [dialog, setDialog] = useState({});
  const dispatch = useDispatch();
  const { push } = useHistory();
  
  useEffect(() => {
    const fetchData = () => {
      dispatch(getPlaylist());
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (!isAuth) {
      push("/login");
    }
  }, [isAuth, push]);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (isAuth) {
      setShow(true);
    } else {
      push("/login");
    }
  };

  const onSubmit = (data) => {
    dispatch(createPlaylist(data, handleClose));
  };

  const deletePlayListAction = (e, data) => {
    e.preventDefault();
    data.message = "Are you sure, you want to delete ";
    data.dialogTitle = "Delete PlayList";
    data.open = true;
    setDialog(data);
    dispatch(loadDialogData(true));
  };

  const handleDeleteClick = (data) => {
    dispatch(deletePlayList(data));
  };

  const playListAction = (e, data) => {
    e.preventDefault();
    dispatch(playListItemAction(data));
    push("/playlist/music");
  };

  return (
    <Fragment>
      <div>
        <div className="create-new-playlist">
          <Button className="float-start" onClick={handleShow}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.875 7.5H8.5V5.125C8.5 5.05625 8.44375 5 8.375 5H7.625C7.55625 5 7.5 5.05625 7.5 5.125V7.5H5.125C5.05625 7.5 5 7.55625 5 7.625V8.375C5 8.44375 5.05625 8.5 5.125 8.5H7.5V10.875C7.5 10.9437 7.55625 11 7.625 11H8.375C8.44375 11 8.5 10.9437 8.5 10.875V8.5H10.875C10.9437 8.5 11 8.44375 11 8.375V7.625C11 7.55625 10.9437 7.5 10.875 7.5Z"
                fill="white"
              />
              <path
                d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM8 13.8125C4.79063 13.8125 2.1875 11.2094 2.1875 8C2.1875 4.79063 4.79063 2.1875 8 2.1875C11.2094 2.1875 13.8125 4.79063 13.8125 8C13.8125 11.2094 11.2094 13.8125 8 13.8125Z"
                fill="white"
              />
            </svg>{" "}
            Create Playlist
          </Button>
        </div>
      </div>
      <div className="my-playlist-section">
        {playlistData &&
          playlistData.length > 0 &&
          playlistData.map((listItem, key) => (
            <div className="playlist-profile mb-4" key={key}>
              <div className="playlist-profile-img">
                {listItem.item.length > 0 ? (
                  <img
                    src={listItem.item[0].image_thumb_url}
                    alt="playlist_logo"
                  />
                ) : (
                  <img src={LOGO_SORT} alt="playlist_logo" />
                )}
              </div>
              <div className="playlist-profile-info">
                <h3>{listItem.title}</h3>
                <p>
                  Just Updated · <span>{listItem.item.length} Songs</span>
                </p>
                <div className="playlist-profile-buttons">
                  <Button
                    className="playbtn"
                    onClick={(e) => playListAction(e, listItem)}
                  >
                    <svg
                      width="320"
                      height="384"
                      viewBox="0 0 320 384"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 384L320 192L0 0V384Z" fill="currentColor" />
                    </svg>{" "}
                    Play
                  </Button>
                  <Dropdown className="common-dropdown">
                    <Dropdown.Toggle variant="link">
                      <span>
                        <svg
                          width="40"
                          height="11"
                          viewBox="0 0 40 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M29.75 5.25C29.75 3.92392 30.2768 2.65215 31.2145 1.71447C32.1521 0.776784 33.4239 0.25 34.75 0.25C36.0761 0.25 37.3479 0.776784 38.2855 1.71447C39.2232 2.65215 39.75 3.92392 39.75 5.25C39.75 6.57608 39.2232 7.84785 38.2855 8.78553C37.3479 9.72322 36.0761 10.25 34.75 10.25C33.4239 10.25 32.1521 9.72322 31.2145 8.78553C30.2768 7.84785 29.75 6.57608 29.75 5.25ZM15.2045 5.25C15.2045 3.92392 15.7313 2.65215 16.669 1.71446C17.6067 0.776783 18.8785 0.249999 20.2045 0.249999C21.5306 0.249999 22.8024 0.776783 23.7401 1.71447C24.6778 2.65215 25.2045 3.92392 25.2045 5.25C25.2045 6.57608 24.6778 7.84785 23.7401 8.78553C22.8024 9.72322 21.5306 10.25 20.2045 10.25C18.8785 10.25 17.6067 9.72322 16.669 8.78553C15.7313 7.84785 15.2045 6.57608 15.2045 5.25ZM5.65909 0.249999C4.33301 0.249998 3.06124 0.776782 2.12356 1.71446C1.18588 2.65215 0.659092 3.92392 0.659092 5.25C0.659092 6.57608 1.18588 7.84785 2.12356 8.78553C3.06124 9.72321 4.33301 10.25 5.65909 10.25C6.98517 10.25 8.25694 9.72322 9.19463 8.78553C10.1323 7.84785 10.6591 6.57608 10.6591 5.25C10.6591 3.92392 10.1323 2.65215 9.19463 1.71446C8.25694 0.776783 6.98517 0.249999 5.65909 0.249999V0.249999Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="#!"
                        onClick={(e) => playListAction(e, listItem)}
                      >
                        Play Song Now
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#!"
                        onClick={(e) => deletePlayListAction(e, listItem)}
                      >
                        Delete Playlist
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          ))}
      </div>
      {show && (
        <Offcanvas
          className="common-modal contactform"
          show={show}
          onHide={handleClose}
          placement="end"
        >
          <Offcanvas.Body>
            <div className="create-playlist">
              <h4 className="modal-title">Create a New Playlist</h4>
              <div className="create-playlist-form">
                <Form
                  onSubmit={handleSubmit(onSubmit)}
                  autoComplete="off"
                  className="d-flex justify-content-between flex-column"
                >
                  <Form.Group className="form-group" controlId="title">
                    <Form.Control
                      type="text"
                      placeholder="Title*"
                      name="title"
                      ref={register({
                        required: true,
                      })}
                    />
                    {errors.title && errors.title.type === "required" && (
                      <span role="alert" className="color">
                        Please enter your title.
                      </span>
                    )}
                  </Form.Group>

                  <Button className="saveBtn" type="submit">
                    Create
                  </Button>
                </Form>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      )}
      {dialogOpen && (
        <Delete dialog={dialog} handleDeleteClick={handleDeleteClick} />
      )}
    </Fragment>
  );
};

export default PlayList;
