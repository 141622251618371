export const LOGIN = "auth/loginEmail";
export const FORGOT_PASSWORD = "auth/forgot-password";
export const SETTING = "setting";
export const CMS_PAGE = "cms/page";
export const HOME_PAGE = "cms/page/home";
export const HOME_PAGE_MUSIC_LIST = "cms/page/home/music";
export const HOME_PAGE_VIDEO = "cms/page/video";
export const HOME_PAGE_CATEGORY = "cms/page/home/category";

export const HOME_PAGE_MUSIC = "cms/page/home/musicFilter";

export const HOME_PAGE_SEARCH = "cms/page/home/searchKeyword";
export const HOME_PAGE_DETAILS = "cms/page/home/details/web";
export const SUBMIT_CONTACT_REQUEST = "cms/contactUs";
export const SEND_OTP = "auth/resend";
export const CHECK_OTP = "auth/verifyOTP";
export const LOAD_FAQ = "faq/list";
export const RESET_PASSWORD = "auth/update-password-with-token";
export const UPDATE_PASSWORD_OTP = "auth/update-password-with-otp";
export const UPDATE_PROFILE = "users/profile-update";
export const UPDATE_PASSWORD = "users/change-password";
export const DELETE_NOTIFICATION = "notification/delete";
export const GET_NOTIFICATION = "notification";
export const LOAD_REGISTRATION_GROUP_REQUEST = "registrationGroup/list";
export const LOAD_AUDIT_REQUEST = "auditRequest/auditor";
export const LOAD_DOCUMENT_DATA = "document/getPrimaryDocument";
export const UPDATE_PRIMARY_DOCUMENT = "document/primary";
export const UPDATE_MULTI_DOCUMENT = "document/secondary";
export const LOAD_DOCUMENT_DATA_SECONDARY = "document/getSecondaryDocument";
export const UPDATE_DOCUMENT = "document/updateDocument";
export const DELETE_DOCUMENT = "document/deleteDocument";
export const UPDATE_DOCUMENT_STATUS = "document/updateDocumentStatus";
export const UPDATE_SINGLE_DOCUMENT = "document/singleDocument";
export const LOAD_AUDITOR = "auditor";
export const SUBMIT_DOCUMENT_AUDIT_REQUEST = "document/submitRequest";
export const LOAD_AUDIT_REQUEST_DATA = "auditRequest/documentList";
export const OTHER_DOCUMENTS = "companyDocument";
export const UPDATE_AUDIT_REQUEST = "auditRequest/updateRequest";
export const UPLOAD_REPORT = "auditRequest/uploadReport";
export const GET_DASHBOARD_DATA = "users/getDashboard";
export const DOWNLOAD_KEY_PERSONNEL = "keyPersonal/download";
export const UPLOAD_REPORT_NEW = "auditRequest/uploadReportNew";
export const DOCUMENT_REQUEST = "auditRequest/documentRequest";
export const DOWNLOAD_DOCUMENT_LIST = "document/downloadDocument";
export const DOWNLOAD_DOCUMENT_DATA_SECONDARY =
  "document/downloadSecondaryDocument";
export const DOWNLOAD_ZIP = "document/downloadZip";
export const GET_DOCUMENT_REJECT = "document/rejectionReasons";
export const GET_CATEGORY = "category/list";
export const PLAYER_DATA = "cms/playerData?id=";
export const AUTH_REGISTER = "auth/register";
export const AUTH_LOGIN_MOBILE = "auth/loginMobile";
export const PLAY_LIST_CREATE = "playlist";
export const MOOD_CATEGORY = "category/getList/mood";
export const UPDATE_MOOD = "users/updateMood";

export const BANNER = "cms/page/banner";
