import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import * as API from "../utils/apiPath";
import toasterStatusAction from "./toasterStatusAction";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import { setToaster } from "../utils/helpers";

export function loadHomePageDataSuccess(homePageData) {
  return { type: types.LOAD_HOME_PAGE_DATA_SUCCESS, homePageData };
}

export function loadBannerDataSuccess(bannerData) {
  return { type: types.LOAD_BANNER_DATA_SUCCESS, bannerData };
}

export function loadPageDataSuccess(pageData) {
  return { type: types.LOAD_PAGE_DATA_SUCCESS, pageData };
}

export function loadFaqDataSuccess(faqData) {
  return { type: types.LOAD_FAQ_DATA_SUCCESS, faqData };
}

export function loadPlayListDataSuccess(playlistData) {
  return { type: types.LOADED_PLAYLIST_DATA_SUCCESS, playlistData };
}

export const loadPageData = (slug) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.get(API.CMS_PAGE + slug);
    dispatch(loadPageDataSuccess(response.data.data));
    dispatch(toggleNetworkRequestStatus(false));
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

export const loadPlayerData = (slug) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.get(API.PLAYER_DATA + slug);
    dispatch(loadPageDataSuccess(response.data.data));
    dispatch(toggleNetworkRequestStatus(false));
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

export const submitContactRequest = (params) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.post(API.SUBMIT_CONTACT_REQUEST, params);
    setToaster(response.data.message);
    dispatch(toggleNetworkRequestStatus(false));
  } catch (error) {
    setToaster(error.message);
    dispatch(toggleNetworkRequestStatus(false));
  }
};

export function loadFaqData() {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .get(API.LOAD_FAQ)
      .then((response) => {
        dispatch(loadFaqDataSuccess(response.data.data));
        dispatch(toggleNetworkRequestStatus(false));
      })
      .catch((error) => {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
}

export const getHomePage = (request) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.get(API.HOME_PAGE, { params: request });
    dispatch(loadHomePageDataSuccess(response.data.data));
    dispatch(loadBannerDataSuccess(response.data.data.bannerData));
    dispatch(toggleNetworkRequestStatus(false));
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

export const getHomePageMusic = (request) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.get(API.HOME_PAGE_MUSIC_LIST, {
      params: request,
    });
    dispatch(loadHomePageDataSuccess(response.data.data));
    dispatch(toggleNetworkRequestStatus(false));
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

export const getBanner = (request) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.get(API.BANNER, { params: request });
    dispatch(loadBannerDataSuccess(response.data.data));
    dispatch(toggleNetworkRequestStatus(false));
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

export const getHomePageCategory = (request) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.get(API.HOME_PAGE_CATEGORY, {
      params: request,
    });
    dispatch(loadHomePageDataSuccess(response.data.data));
    dispatch(toggleNetworkRequestStatus(false));
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

export const getHomePageSearch = (request) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    if ((request.type = "AUDIO")) {
      const response = await agent.get(API.HOME_PAGE_SEARCH, {
        params: request,
      });
      dispatch(loadHomePageDataSuccess(response.data.data));
      dispatch(toggleNetworkRequestStatus(false));
    } else {
      const response = await agent.get(API.HOME_PAGE_CATEGORY, {
        params: request,
      });
      dispatch(loadHomePageDataSuccess(response.data.data));
      dispatch(toggleNetworkRequestStatus(false));
    }
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

export const getHomePageDetails = (request) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.get(API.HOME_PAGE_DETAILS, {
      params: request,
    });
    dispatch(loadHomePageDataSuccess(response.data.data));
    dispatch(toggleNetworkRequestStatus(false));
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

export const getHomePageVideo = () => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.get(API.HOME_PAGE_VIDEO);
    dispatch(loadHomePageDataSuccess(response.data.data));
    dispatch(toggleNetworkRequestStatus(false));
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

export function loadCategoryDataSuccess(categoryData) {
  return { type: types.LOADED_CATEGORY_DATA_SUCCESS, categoryData };
}

export const getCategory = (request) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.get(API.GET_CATEGORY, {
      params: request,
    });
    dispatch(loadCategoryDataSuccess(response.data.data));
    dispatch(toggleNetworkRequestStatus(false));
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

export const createPlaylist = (params, handleClose) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.post(API.PLAY_LIST_CREATE, params);
    dispatch(getPlaylist());
    setToaster(response.data.message);
    dispatch(toggleNetworkRequestStatus(false));
    handleClose();
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};
export const addMusicPlayList = (params, handleClose) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.post(
      `${API.PLAY_LIST_CREATE}/add_music`,
      params
    );
    dispatch(getPlaylist());
    setToaster(response.data.message);
    dispatch(toggleNetworkRequestStatus(false));
    handleClose();
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

export const getPlaylist = () => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.get(API.PLAY_LIST_CREATE);
    dispatch(loadPlayListDataSuccess(response.data.data));
    dispatch(toggleNetworkRequestStatus(false));
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

export const deletePlayList = (params) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.delete(
      `${API.PLAY_LIST_CREATE}?id=${params._id}`
    );
    dispatch(getPlaylist());
    setToaster(response.data.message);
    dispatch(toggleNetworkRequestStatus(false));
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

export function loadPlayListInfoDataSuccess(playListItem) {
  return { type: types.LOADED_PLAYLIST_ITEM_SUCCESS, playListItem };
}

export const playListItemAction = (params) => async (dispatch) => {
  try {
    dispatch(loadPlayListInfoDataSuccess(params));
  } catch (error) {
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

export const deleteFromPlayList = (params, handleClose) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.post(
      `${API.PLAY_LIST_CREATE}/remove_music`,
      params
    );
    dispatch(loadPlayListInfoDataSuccess(response.data.data));
    dispatch(getPlaylist());
    setToaster(response.data.message);
    dispatch(toggleNetworkRequestStatus(false));
    handleClose();
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

export function loadMoodDataSuccess(myMood) {
  return { type: types.LOAD_MOOD_DATA_SUCCESS, myMood };
}

export const getMoodList = (request) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.get(API.MOOD_CATEGORY, {
      params: request,
    });
    dispatch(loadMoodDataSuccess(response.data.data));
    dispatch(toggleNetworkRequestStatus(false));
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

export const getHomeMusic = (request) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.get(API.HOME_PAGE_MUSIC, {
      params: request,
    });
    dispatch(loadHomePageDataSuccess(response.data.data));
    dispatch(toggleNetworkRequestStatus(false));
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};
