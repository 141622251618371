import React, { useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadPageData } from "../../actions/homePageActions";
import { Row, Col } from "react-bootstrap";
const Index = (props) => {
  const slug = props.location.pathname;
  const dispatch = useDispatch();
  const { pageData } = useSelector((state) => ({
    pageData: state.pageData,
  }));

  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" };
  };

  useEffect(() => {
    const fetchData = () => {
      dispatch(loadPageData(slug));
    };
    fetchData();
  }, [dispatch, slug]);

  return (
    <Fragment>
      <div className="aboutus-page-section">
        <h2>{pageData.title}</h2>

        <Row className="mt-5">
          <Col md={12}>
            <div className="about-content">
              <div
                className="about-page-text"
                dangerouslySetInnerHTML={prepareHtml(pageData.content)}
              ></div>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Index;
