import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { submitContactRequest } from "../../actions/homePageActions";

const Index = () => {
  const { setting } = useSelector((state) => ({
    setting: state.setting,
  }));
  const { register, errors, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(submitContactRequest(data));
  };

  return (
    <>
      <div className="contactus-page-section">
        <Container>
          <Row className="g-0">
            <Col md={6}>
              <div
                className="contactus-content"
                style={{
                  backgroundImage: `url("./assets/images/contactus-bg.jpg")`,
                }}
              >
                <div className="contactinfo">
                  <h1>1R FM</h1>
                  <div className="single-contact">
                    <h4>Address</h4>
                    <p>{setting.address}</p>
                  </div>
                  <div className="single-contact">
                    <h4>Email Address</h4>
                    <p>{setting.support_email}</p>
                  </div>
                  <div className="single-contact">
                    <h4>Contact number</h4>
                    <p>+91-{setting.number}</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="contactform">
                <h1>Contact Us</h1>
                <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                  <Row>
                    <Col md={6}>
                      <Form.Group
                        className="form-group"
                        controlId="formLastName"
                      >
                        <Form.Control
                          type="text"
                          name="first_name"
                          placeholder="First Name*"
                          ref={register({
                            required: true,
                            minLength: 3,
                            maxLength: 20,
                          })}
                        />
                        {errors.first_name &&
                          errors.first_name.type === "required" && (
                            <span role="alert" className="color">
                              Please enter your first name.
                            </span>
                          )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="form-group"
                        controlId="formLastName"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Last Name*"
                          name="last_name"
                          ref={register({
                            required: true,
                            minLength: 3,
                            maxLength: 20,
                          })}
                        />
                        {errors.last_name &&
                          errors.last_name.type === "required" && (
                            <span role="alert" className="color">
                              Please enter your first name.
                            </span>
                          )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="form-group"
                        controlId="formEmailAddress"
                      >
                        <Form.Control
                          type="email"
                          placeholder="Email Address*"
                          name="email"
                          ref={register({
                            required: true,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            },
                            maxLength: 50,
                          })}
                        />
                        {errors.email && errors.email.type === "required" && (
                          <span role="alert" className="color">
                            Please enter your email address.
                          </span>
                        )}
                        {errors.email && errors.email.type === "pattern" && (
                          <span role="alert" className="color">
                            Invalid email address.
                          </span>
                        )}
                        {errors.email && errors.email.type === "maxLength" && (
                          <span role="alert" className="color">
                            Your email should not exceed 50 characters.
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="form-group"
                        controlId="formPhoneNumber"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Phone Number*"
                          name="number"
                          ref={register({
                            required: true,
                            minLength: 10,
                            maxLength: 10,
                            pattern: {
                              value: /^(\+\d{1,3}[- ]?)?\d{10}$/i,
                            },
                          })}
                        />
                        {errors.number && errors.number.type === "required" && (
                          <span role="alert" className="color">
                            Please enter your mobile number.
                          </span>
                        )}
                        {errors.number && errors.number.type === "maxLength" && (
                          <span role="alert" className="color">
                            Your mobile number should not exceed 10 digits.
                          </span>
                        )}
                        {errors.number && errors.number.type === "minLength" && (
                          <span role="alert" className="color">
                            Your mobile number should contain atleast 10 digits.
                          </span>
                        )}
                        {errors.number && errors.number.type === "pattern" && (
                          <span role="alert" className="color">
                            Please enter correct mobile number. It should be
                            entered without country code.( Example xxxxxxxxxx)
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group
                        className="form-group"
                        controlId="formPhoneNumber"
                      >
                        <Form.Control
                          as="textarea"
                          placeholder="Write your message here..."
                          ref={register({
                            required: true,
                            minLength: 5,
                          })}
                          name="message"
                        />
                        {errors.message && errors.message.type === "required" && (
                          <span role="alert" className="color">
                            Please enter your message.
                          </span>
                        )}
                        {errors.message && errors.message.type === "minLength" && (
                          <span role="alert" className="color">
                            Your message should contain atleast 5 characters.
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Index;
