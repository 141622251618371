import React, { Fragment, useEffect } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getHomePage } from "../../actions/homePageActions";

const Home = () => {
  const dispatch = useDispatch();
  const { homePageData } = useSelector((state) => ({
    homePageData: state.homePageData,
  }));
  useEffect(() => {
    const fetchData = () => {
      dispatch(getHomePage());
    };
    fetchData();
  }, [dispatch]);

  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" };
  };

  return (
    <Fragment>
      {homePageData.allNews && (
        <section className="news-section">
          <Container className="position-relative">
            <Row>
              <Col md="12">
                <div className="main-title">
                  <h2>Media Coverage</h2>
                </div>
              </Col>
            </Row>
            {homePageData.mediaPage.content && (
              <Row>
                <Col md="12">
                  <div className="about-content">
                    <div
                      className="about-page-text"
                      dangerouslySetInnerHTML={prepareHtml(
                        homePageData.mediaPage.content
                      )}
                    ></div>
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              <Table className="table table-bordered table-style1" responsive>
                <thead>
                  <tr>
                    <th scope="col">S. No.</th>
                    <th scope="col">Name</th>
                    <th scope="col">Head Line</th>
                    <th scope="col">Link</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {homePageData.mediaCoverage &&
                    homePageData.mediaCoverage.map((item, key) => (
                      <tr key={key}>
                        <th>{key + 1}</th>
                        <td>{item.title}</td>
                        <td>
                          <div
                            dangerouslySetInnerHTML={prepareHtml(
                              item.head_line
                            )}
                          ></div>
                        </td>
                        <td>
                          <a
                            href="#!"
                            onClick={(event) => {
                              event.preventDefault();
                              window.open(item.link);
                            }}
                          >
                            {item.link}
                          </a>
                        </td>
                        <td>{item.date}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Row>
          </Container>
        </section>
      )}
    </Fragment>
  );
};

export default Home;
