import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function myMoodReducer(state = initialState.myMood, action) {
  switch (action.type) {
    case types.LOAD_MOOD_DATA_SUCCESS:
      return action.myMood;
    default:
      return state;
  }
}
