import React, { Fragment } from "react";
import { LOGIN_BG } from "../../assets/images";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { loginWithMobile } from "../../actions/userActions";
import { useHistory } from "react-router-dom";

const Login = () => {
  const { register, errors, handleSubmit } = useForm();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    dispatch(loginWithMobile(data, push));
  };

  return (
    <Fragment>
      <div className="create-account-section">
        <img className="create-account-bg" src={LOGIN_BG} alt="login_bg" />
        <div className="createaccount-content row gx-0">
          <div className="col-md-8 createaccount-form order-md-last">
            <Form
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
              className="d-flex justify-content-between flex-column"
            >
              <div className="account-form-content">
                <h2>Welcome to 1R-FM</h2>
                <p className="info">Log in with your mobile number.</p>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Mobile Number*</Form.Label>
                  <Form.Control
                    type="text"
                    name="mobile_number"
                    ref={register({
                      required: true,
                      minLength: 10,
                      maxLength: 10,
                      pattern: {
                        value: /^(\+\d{1,3}[- ]?)?\d{10}$/i,
                      },
                    })}
                  />
                  {errors.mobile_number &&
                    errors.mobile_number.type === "required" && (
                      <span role="alert" className="color">
                        Please enter your mobile number.
                      </span>
                    )}
                  {errors.mobile_number &&
                    errors.mobile_number.type === "maxLength" && (
                      <span role="alert" className="color">
                        Your mobile number should not exceed 10 digits.
                      </span>
                    )}
                  {errors.mobile_number &&
                    errors.mobile_number.type === "minLength" && (
                      <span role="alert" className="color">
                        Your mobile number should contain atleast 10 digits.
                      </span>
                    )}
                  {errors.mobile_number &&
                    errors.mobile_number.type === "pattern" && (
                      <span role="alert" className="color">
                        Please enter correct mobile number. It should be entered
                        without country code.( Example xxxxxxxxxx)
                      </span>
                    )}
                </Form.Group>
                <Button
                  className="btn main-btn"
                  variant="primary"
                  type="submit"
                >
                  Submit
                </Button>
              </div>

              <div className="donthaveaccount mt-5">
                <p className="mb-0">
                  Don't have a 1R-FM account yet?{" "}
                  <Link to="/signUp">Sign Up</Link>
                </p>
              </div>
            </Form>
          </div>
          <div className="col-md-4 login-with-socialmedia-section order-md-first">
            <div className="login-with-socialmedia">
              <h2>Login with</h2>
              <p className="info">Login with social media platforms.</p>
              <ul className="p-0 m-0">
                <li>
                  <Link to="/login" className="twitter">
                    Sign In with Email
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
