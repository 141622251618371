import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function kids_safeRequest(
  state = initialState.kids_safe,
  action
) {
  switch (action.type) {
    case types.KIDS_SAFE:
      return action.kids_safe;
    default:
      return state;
  }
}
