import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useParams } from "react-router-dom";
import {
  getHomePageDetails,
  createPlaylist,
  getPlaylist,
  addMusicPlayList,
} from "../../actions/homePageActions";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { ShareSocial } from "react-share-social";
import { useForm } from "react-hook-form";
import { LOGO_SORT } from "../../assets/images";

const style = {
  root: {
    background: "rgb(35 35 35)",
    borderRadius: 3,
    border: 0,
    boxShadow: "rgb(35 35 35)",
    color: "white",
  },
  copyContainer: {
    border: "1px solid blue",
    background: "rgb(35 35 35)",
    display: "none",
  },
  title: {
    color: "aquamarine",
    fontStyle: "italic",
  },
};

function Home() {
  const { register, errors, handleSubmit } = useForm();
  const [show, setShow] = useState(false);
  const [tag, setTag] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { push } = useHistory();
  const { homePageData, isAuth, playlistData, kids_safe } = useSelector(
    (state) => ({
      homePageData: state.homePageData,
      isAuth: state.isAuth,
      playlistData: state.playlistData,
      kids_safe: state.kids_safe,
    })
  );
  const { id } = useParams();
  useEffect(() => {
    const fetchData = () => {
      var request = {};
      request.type = "AUDIO";
      request.id = id;
      request.kids_safe = kids_safe;
      dispatch(getHomePageDetails(request));
    };
    fetchData();
  }, [dispatch, id, kids_safe]);

  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" };
  };

  const openPage = (e, item) => {
    e.preventDefault();
    history.push(`/audio/details/${item._id}`);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (isAuth) {
      setShow(true);
    } else {
      push("/login");
    }
  };

  const onSubmit = (data) => {
    dispatch(createPlaylist(data, handleClose));
  };

  useEffect(() => {
    if (isAuth) {
      dispatch(getPlaylist());
    }
  }, [isAuth, dispatch]);

  useEffect(() => {
    if (homePageData?.details?.tag) {
      let newArray = homePageData.details.tag.split(",");
      setTag(newArray);
    }
  }, [homePageData]);

  const addMusic = (playlist_id) => {
    const request = {};
    request.playlist_id = playlist_id;
    request.music_id = id;
    dispatch(addMusicPlayList(request, handleClose));
  };

  return (
    <>
      {homePageData?.details && (
        <div className="story-details-section">
          <Row>
            <Col md={6}>
              <div className="storyAudioPlayer pt-2">
                <div className="story-preview">
                  <div className="music-icon">
                    <img
                      src={homePageData.details.image_thumb_url}
                      alt={`${homePageData.details.title}`}
                    />
                  </div>
                  <div className="AudioPlayer">
                    <AudioPlayer
                      volume={0.1}
                      src={
                        homePageData.details.liveStreaming === "YES"
                          ? homePageData.details.url
                          : homePageData.details.radio_url
                      }
                      onPlay={(e) => console.log("onPlay")}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="story-preview-detail">
                <h2>{homePageData.details.title}</h2>
                <div className="story-disc">
                  <div
                    className="about-page-text"
                    dangerouslySetInnerHTML={prepareHtml(
                      homePageData.details.description
                    )}
                  ></div>
                  <div className="story-buttons">
                    <ul>
                      <li>
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.4219 3.43125C13.2124 2.94631 12.9104 2.50686 12.5328 2.13751C12.1549 1.76705 11.7093 1.47265 11.2203 1.27032C10.7132 1.05969 10.1694 0.951872 9.62031 0.953136C8.85 0.953136 8.09844 1.16407 7.44531 1.56251C7.28906 1.65782 7.14062 1.76251 7 1.87657C6.85938 1.76251 6.71094 1.65782 6.55469 1.56251C5.90156 1.16407 5.15 0.953136 4.37969 0.953136C3.825 0.953136 3.2875 1.05939 2.77969 1.27032C2.28906 1.47345 1.84687 1.76563 1.46719 2.13751C1.08906 2.50645 0.786997 2.946 0.578125 3.43125C0.360938 3.93594 0.25 4.47188 0.25 5.02344C0.25 5.54375 0.35625 6.08593 0.567188 6.63749C0.74375 7.09843 0.996875 7.57655 1.32031 8.05937C1.83281 8.82343 2.5375 9.6203 3.4125 10.4281C4.8625 11.7672 6.29844 12.6922 6.35938 12.7297L6.72969 12.9672C6.89375 13.0719 7.10469 13.0719 7.26875 12.9672L7.63906 12.7297C7.7 12.6906 9.13437 11.7672 10.5859 10.4281C11.4609 9.6203 12.1656 8.82343 12.6781 8.05937C13.0016 7.57655 13.2562 7.09843 13.4312 6.63749C13.6422 6.08593 13.7484 5.54375 13.7484 5.02344C13.75 4.47188 13.6391 3.93594 13.4219 3.43125ZM7 11.7312C7 11.7312 1.4375 8.16718 1.4375 5.02344C1.4375 3.43125 2.75469 2.14063 4.37969 2.14063C5.52187 2.14063 6.5125 2.77813 7 3.70938C7.4875 2.77813 8.47813 2.14063 9.62031 2.14063C11.2453 2.14063 12.5625 3.43125 12.5625 5.02344C12.5625 8.16718 7 11.7312 7 11.7312Z"
                            fill="white"
                          />
                        </svg>
                        &nbsp;{homePageData.details.view} Likes
                      </li>
                      <li>
                        <svg
                          width="14"
                          height="13"
                          viewBox="0 0 14 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 2.5C0 1.83696 0.263392 1.20107 0.732233 0.732233C1.20107 0.263392 1.83696 0 2.5 0H11.5C12.163 0 12.7989 0.263392 13.2678 0.732233C13.7366 1.20107 14 1.83696 14 2.5V7.5C14 7.8283 13.9353 8.15339 13.8097 8.45671C13.6841 8.76002 13.4999 9.03562 13.2678 9.26777C13.0356 9.49991 12.76 9.68406 12.4567 9.8097C12.1534 9.93534 11.8283 10 11.5 10H7.688L4.625 12.68C4.48327 12.8039 4.30885 12.8844 4.12261 12.9118C3.93636 12.9391 3.74617 12.9123 3.57476 12.8345C3.40336 12.7566 3.25801 12.6311 3.15608 12.4728C3.05415 12.3145 2.99996 12.1303 3 11.942V10H2.5C1.83696 10 1.20107 9.73661 0.732233 9.26777C0.263392 8.79893 0 8.16304 0 7.5V2.5ZM2.5 1C2.10218 1 1.72064 1.15804 1.43934 1.43934C1.15804 1.72064 1 2.10218 1 2.5V7.5C1 7.89782 1.15804 8.27936 1.43934 8.56066C1.72064 8.84196 2.10218 9 2.5 9H4V11.898L7.312 9H11.5C11.8978 9 12.2794 8.84196 12.5607 8.56066C12.842 8.27936 13 7.89782 13 7.5V2.5C13 2.10218 12.842 1.72064 12.5607 1.43934C12.2794 1.15804 11.8978 1 11.5 1H2.5Z"
                            fill="white"
                          />
                        </svg>{" "}
                        &nbsp;{homePageData.details.rating} Comments
                      </li>
                      {homePageData.details.tag && (
                        <li>
                          Tags:&nbsp;
                          {tag &&
                            tag.map((tagItem) => (
                              <>
                                <Badge bg="secondary" key={tagItem}>
                                  {tagItem}&nbsp;
                                </Badge>
                                &nbsp;&nbsp;
                              </>
                            ))}
                        </li>
                      )}
                    </ul>
                    <ul>
                      <li>
                        <Button className="float-start" onClick={handleShow}>
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.875 7.5H8.5V5.125C8.5 5.05625 8.44375 5 8.375 5H7.625C7.55625 5 7.5 5.05625 7.5 5.125V7.5H5.125C5.05625 7.5 5 7.55625 5 7.625V8.375C5 8.44375 5.05625 8.5 5.125 8.5H7.5V10.875C7.5 10.9437 7.55625 11 7.625 11H8.375C8.44375 11 8.5 10.9437 8.5 10.875V8.5H10.875C10.9437 8.5 11 8.44375 11 8.375V7.625C11 7.55625 10.9437 7.5 10.875 7.5Z"
                              fill="white"
                            />
                            <path
                              d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM8 13.8125C4.79063 13.8125 2.1875 11.2094 2.1875 8C2.1875 4.79063 4.79063 2.1875 8 2.1875C11.2094 2.1875 13.8125 4.79063 13.8125 8C13.8125 11.2094 11.2094 13.8125 8 13.8125Z"
                              fill="white"
                            />
                          </svg>{" "}
                          Add to Playlist
                        </Button>
                      </li>
                      <li>
                        <ShareSocial
                          url={window.location.href}
                          socialTypes={["facebook", "whatsapp"]}
                          style={style}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <div className="similar-story-list">
            <div className="similar-story-title">
              <h3>Related Audio({homePageData.similar.length})</h3>
            </div>
            <Row>
              {homePageData?.similar &&
                homePageData?.similar.map((videoItem, key) => (
                  <Col md={3} sm={6} key={key}>
                    <div className="audiobook-card">
                      <a
                        href="#!"
                        className="audiobook-image"
                        onClick={(e) => openPage(e, videoItem)}
                      >
                        <img src={videoItem.image_thumb_url} alt="" />
                      </a>
                      <div
                        className="audiobook-content"
                        onClick={(e) => openPage(e, videoItem)}
                      >
                        <a href="#!">{videoItem.title}</a>
                      </div>
                    </div>
                  </Col>
                ))}
            </Row>
          </div>
        </div>
      )}
      {show && (
        <Offcanvas
          className="common-modal contactform"
          show={show}
          onHide={handleClose}
          placement="end"
        >
          <Offcanvas.Body>
            <div className="create-playlist">
              <h4 className="modal-title">Create a New Playlist</h4>
              <div className="create-playlist-form">
                <Form
                  onSubmit={handleSubmit(onSubmit)}
                  autoComplete="off"
                  className="d-flex justify-content-between flex-column"
                >
                  <Form.Group className="form-group" controlId="title">
                    <Form.Control
                      type="text"
                      placeholder="Title*"
                      name="title"
                      ref={register({
                        required: true,
                      })}
                    />
                    {errors.title && errors.title.type === "required" && (
                      <span role="alert" className="color">
                        Please enter your title.
                      </span>
                    )}
                  </Form.Group>

                  <Button className="saveBtn" type="submit">
                    Create
                  </Button>
                </Form>
              </div>
            </div>
            {playlistData && playlistData.length > 0 && (
              <div className="all-playlist">
                <h4 className="modal-title">OR ADD TO AN EXISTING PLAYLIST</h4>
                <ul>
                  {playlistData.map((listItem, key) => (
                    <li key={key} onClick={() => addMusic(listItem)}>
                      <div className="single-playlist-item">
                        <div className="playlist-img">
                          {listItem.item.length > 0 ? (
                            <img
                              src={listItem.item[0].image_thumb_url}
                              alt="playlist_logo"
                            />
                          ) : (
                            <img src={LOGO_SORT} alt="playlist_logo" />
                          )}
                        </div>
                        <div className="playlist-title">
                          <h5>{listItem.title}</h5>
                          <p>{listItem.item.length} Songs</p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
}

export default Home;
