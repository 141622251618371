import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { LOGIN_BG } from "../../assets/images";
import { useHistory } from "react-router-dom";
import { getMoodList } from "../../actions/homePageActions";
import { updateMood } from "../../actions/userActions";
import { setToaster } from "../../utils/helpers";

const UpdateMood = () => {
  const { isAuth, kids_safe, myMood, userInfo } = useSelector((state) => ({
    isAuth: state.isAuth,
    userInfo: state.userInfo,
    kids_safe: state.kids_safe,
    myMood: state.myMood,
  }));
  const [music_mood, setMusicMood] = useState([]);
  const dispatch = useDispatch();
  const { push } = useHistory();

  useEffect(() => {
    if (!isAuth) {
      push("/login");
    }
  }, [isAuth, push]);

  useEffect(() => {
    const fetchData = () => {
      const request = {};
      request.kids_safe = kids_safe;
      dispatch(getMoodList(request));
    };
    fetchData();
  }, [dispatch, kids_safe]);

  useEffect(() => {
    setMusicMood(userInfo.music_mood);
  }, [userInfo.music_mood]);

  const handleChange = (event) => {
    const target = event.target;
    var value = JSON.parse(target.value);
    if (target.checked) {
      setMusicMood((music_mood) => [...music_mood, value]);
    } else {
      const updateValue = music_mood.filter((item) => item._id !== value._id);
      setMusicMood(updateValue);
    }
  };

  const submitMood = () => {
    if (music_mood.length === 0) {
      setToaster("Please select at least 1 mood category for music.");
    } else {
      const request = {};
      request.mood = music_mood;
      dispatch(updateMood(request, push));
    }
  };

  return (
    <Fragment>
      <div className="create-account-section">
        <img className="create-account-bg" src={LOGIN_BG} alt="update_logo" />
        <div className="choose-mood-section position-relative">
          <h2>Choose your mood</h2>
          <p className="info">
            Please select at least 1 mood category for music.
          </p>

          <div className="choose-mood-list">
            <ul>
              {myMood &&
                myMood.length > 0 &&
                myMood.map((item, key) => (
                  <li className="togglebtn" key={key}>
                    <input
                      className="btn-check"
                      type="checkbox"
                      name="mood"
                      id={`tbg-btn-${key}`}
                      value={JSON.stringify(item)}
                      onChange={handleChange}
                      defaultChecked={userInfo.music_mood.some(
                        (el) => el._id === item._id
                      )}
                    />
                    <label for={`tbg-btn-${key}`} className="btn btn-mood">
                      {item.title}
                    </label>
                  </li>
                ))}
            </ul>
            <div className="w-80 d-inline-block text-center mt-5">
              <Button className="btn main-btn" onClick={submitMood}>
                Update
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UpdateMood;
