import React, { Fragment } from "react";
import { LOGIN_BG } from "../../assets/images";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { forgotPassword, updatePasswordOtp } from "../../actions/userActions";
import { useHistory } from "react-router-dom";

const Login = () => {
  const { register, errors, handleSubmit } = useForm();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { userParams } = useSelector((state) => ({
    userParams: state.userParams,
  }));

  const onSubmit = (data) => {
    data.email = userParams.email;
    dispatch(updatePasswordOtp(data, push));
  };

  const reSentOtp = (e) => {
    e.preventDefault();
    const request = {};
    request.email = userParams.email;
    dispatch(forgotPassword(request, push));
  };

  return (
    <Fragment>
      <div className="create-account-section">
        <img className="create-account-bg" src={LOGIN_BG} alt="login_bg" />
        <div className="createaccount-content row gx-0">
          <div className="col-md-6 createaccount-form order-md-last">
            <Form
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
              className="d-flex justify-content-between flex-column"
            >
              <div className="account-form-content">
                <h2>Enter your 6-digit code.</h2>
                <p className="info">
                  We’ve sent your code to the phone number. +91
                  {userParams.mobile_number} and email {userParams.email} Didn’t
                  get it?&nbsp;
                  <a href="#!" onClick={(e) => reSentOtp(e)}>
                    Resend Code.
                  </a>
                </p>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>OTP*</Form.Label>
                  <Form.Control
                    type="password"
                    name="otp"
                    ref={register({
                      required: true,
                      minLength: 6,
                      maxLength: 6,
                    })}
                  />
                  {errors.otp && errors.otp.type === "required" && (
                    <span role="alert" className="color">
                      Please enter your 6-digit code.
                    </span>
                  )}
                  {errors.otp && errors.otp.type === "maxLength" && (
                    <span role="alert" className="color">
                      Your code should not exceed 6 digits.
                    </span>
                  )}
                  {errors.otp && errors.otp.type === "minLength" && (
                    <span role="alert" className="color">
                      Your code should contain atleast 6 digits.
                    </span>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password*</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Enter Your Password"
                    ref={register({
                      required: true,
                    })}
                  />
                  {errors.password && errors.password.type === "required" && (
                    <span role="alert" className="color">
                      Please enter your password.
                    </span>
                  )}
                </Form.Group>
                <Button
                  className="btn main-btn"
                  variant="primary"
                  type="submit"
                >
                  Submit
                </Button>
              </div>

              <div className="donthaveaccount mt-5">
                <p className="mb-0">
                  Already have an account? <Link to="/login">Login</Link>
                </p>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
