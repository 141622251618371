import HomePage from "../Pages/Home";
import HomePageAll from "../Pages/Home/all";
import CategoryPageAll from "../Pages/Home/category";
import HomePageSearch from "../Pages/Home/search";
import HomePageDetails from "../Pages/Home/details";
import MediaConvergePage from "../Pages/MediaConverge";
import PressPage from "../Pages/Press";
import VideoPage from "../Pages/Video";
import LiveVideoPage from "../Pages/LiveVideo";
import LiveVideoPageDetails from "../Pages/LiveVideo/details";
import VideoPageAll from "../Pages/Video/all";
import VideoPageDetails from "../Pages/Video/details";
import WebPage from "../Pages/Web";
import WebPageAll from "../Pages/Web/all";
import cmsPage from "../Pages/Cms";
import Conditions from "../Pages/Conditions";
import Policy from "../Pages/Policy";
import NotFoundPage from "../Pages/NotFound";
import ContactUs from "../Pages/ContactUs";
import Login from "../Pages/Login";
import LoginMobile from "../Pages/LoginMobile";
import SignUp from "../Pages/SignUp";
import OTP from "../Pages/Otp";
import ForgotPassword from "../Pages/ForgotPassword";
import ResetPassword from "../Pages/ResetPassword";
import UpdatePassword from "../Pages/Password";
import UpdateProfile from "../Pages/Profile";
import UpdateMood from "../Pages/Mood";
import Playlist from "../Pages/Playlist";
import MusicPlaylist from "../Pages/MusicPlaylist";

export const RoutesPage = [
  { path: "/", component: HomePage, title: "Home" },
  {
    path: "/audio/category/:parent_id",
    component: CategoryPageAll,
    title: "Music Item",
  },
  { path: "/audio/:category_id", component: HomePageAll, title: "Web" },
  { path: "/audio/details/:id", component: HomePageDetails, title: "Details" },
  {
    path: "/media-converge",
    component: MediaConvergePage,
    title: "Media Converge",
  },
  { path: "/video", component: VideoPage, title: "Video" },
  { path: "/live-video", component: LiveVideoPage, title: "Live feeds" },
  {
    path: "/live-video/details/:id",
    component: LiveVideoPageDetails,
    title: "Details",
  },
  { path: "/video/:category_id", component: VideoPageAll, title: "video" },
  { path: "/video/details/:id", component: VideoPageDetails, title: "Details" },
  { path: "/web", component: WebPage, title: "Web" },
  { path: "/web/:category_id", component: WebPageAll, title: "Web" },
  { path: "/about-us", component: cmsPage, title: "About Us" },
  {
    path: "/terms-and-conditions",
    component: cmsPage,
    title: "Terms & Conditions",
  },
  {
    path: "/privacy-policy",
    component: cmsPage,
    title: "Privacy Policy",
  },
  {
    path: "/copyright",
    component: cmsPage,
    title: "Copyright",
  },
  { path: "/help", component: cmsPage, title: "Help" },
  { path: "/press", component: PressPage, title: "Press" },
  { path: "/contact-us", component: ContactUs, title: "Contact Us" },
  { path: "/playlist", component: Playlist, title: "Playlist" },
  { path: "/playlist/music", component: MusicPlaylist, title: "Music List" },
  { path: "/update-profile", component: UpdateProfile, title: "Profile" },
  {
    path: "/update-password",
    component: UpdatePassword,
    title: "Update Password",
  },
  { path: "/my-mood", component: UpdateMood, title: "Mood" },

  { path: "/404", component: NotFoundPage, title: "404" },
  { path: "/:type", component: HomePageSearch, title: "Search" },
];
export const AuthPage = [
  { path: "/login", component: Login, title: "Login" },
  { path: "/mobile-login", component: LoginMobile, title: "mobile-login" },
  { path: "/signUp", component: SignUp, title: "SignUp" },
  { path: "/otp", component: OTP, title: "OTP" },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    title: "Forgot Password",
  },
  {
    path: "/reset-password",
    component: ResetPassword,
    title: "Reset Password",
  },
];
export const Routes = [
  {
    path: "/mobile/tram-and-conditions",
    component: Conditions,
    title: "Tram & Conditions",
  },
  {
    path: "/mobile/privacy-policy",
    component: Policy,
    title: "Privacy Policy",
  },
];
