import React, { Fragment, useEffect } from "react";
import ScrollToTop from "../Component/ScrollToTop";
import Loading from "../Component/PreLoader";
import { useSelector, useDispatch } from "react-redux";
import { getSettingData } from "../actions/settingActions";
import { getNotification } from "../actions/notificationActions";
import { getCategory } from "../actions/homePageActions";
import Page from "../Pages";

const App = () => {
  const dispatch = useDispatch();
  const { isAuth, isFetching, kids_safe } = useSelector((state) => ({
    isAuth: state.isAuth,
    isFetching: state.isFetching,
    kids_safe: state.kids_safe,
  }));

  useEffect(() => {
    const fetchData = () => {
      dispatch(getSettingData());
      dispatch(getCategory({ type: "AUDIO", kids_safe: kids_safe }));
    };
    fetchData();
  }, [dispatch, kids_safe]);

  useEffect(() => {
    const fetchData = () => {
      dispatch(getNotification());
    };
    if (isAuth) {
      fetchData();
    }
  }, [dispatch, isAuth]);

  return (
    <Fragment>
      <ScrollToTop />
      {isFetching && <Loading />}
      <Page isAuth={isAuth} />
    </Fragment>
  );
};

export default App;
