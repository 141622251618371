import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { LOGIN_BG } from "../../assets/images";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { userRegister } from "../../actions/userActions";
import { useHistory } from "react-router-dom";

const Login = () => {
  const { register, errors, handleSubmit } = useForm();
  const { push } = useHistory();
  const [selected, setSelected] = useState("Male");
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    data.gender = selected;
    dispatch(userRegister(data, push));
  };

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  return (
    <Fragment>
      <div className="create-account-section">
        <img className="create-account-bg" src={LOGIN_BG} alt="login_bg" />
        <div className="createaccount-content row gx-0">
          <Form
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
            className="d-flex justify-content-between flex-column"
          >
            <div className="row">
              <div className="col-md-6 login-with-socialmedia-section">
                <div className="account-form-content">
                  <h2>Welcome to 1R-FM</h2>
                  <p className="info">
                    Sign up with your mobile & email address.
                  </p>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Name*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Your Name"
                      name="name"
                      ref={register({
                        required: true,
                      })}
                    />
                    {errors.name && errors.name.type === "required" && (
                      <span role="alert" className="color">
                        Please enter your name.
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Mobile Number*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Mobile Number*"
                      name="mobile_number"
                      ref={register({
                        required: true,
                        minLength: 10,
                        maxLength: 10,
                        pattern: {
                          value: /^(\+\d{1,3}[- ]?)?\d{10}$/i,
                        },
                      })}
                    />
                    {errors.mobile_number &&
                      errors.mobile_number.type === "required" && (
                        <span role="alert" className="color">
                          Please enter your mobile number.
                        </span>
                      )}
                    {errors.mobile_number &&
                      errors.mobile_number.type === "maxLength" && (
                        <span role="alert" className="color">
                          Your mobile number should not exceed 10 digits.
                        </span>
                      )}
                    {errors.mobile_number &&
                      errors.mobile_number.type === "minLength" && (
                        <span role="alert" className="color">
                          Your mobile number should contain atleast 10 digits.
                        </span>
                      )}
                    {errors.mobile_number &&
                      errors.mobile_number.type === "pattern" && (
                        <span role="alert" className="color">
                          Please enter correct mobile number. It should be
                          entered without country code.( Example xxxxxxxxxx)
                        </span>
                      )}
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="formBasicMobileNumber"
                  >
                    <Form.Label>Email Address*</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Your Email ID"
                      name="email"
                      ref={register({
                        required: true,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        },
                        maxLength: 50,
                      })}
                    />
                    {errors.email && errors.email.type === "required" && (
                      <span role="alert" className="color">
                        Please enter your email address.
                      </span>
                    )}
                    {errors.email && errors.email.type === "pattern" && (
                      <span role="alert" className="color">
                        Invalid email address.
                      </span>
                    )}
                    {errors.email && errors.email.type === "maxLength" && (
                      <span role="alert" className="color">
                        Your email should not exceed 50 characters.
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password*</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      placeholder="Enter Your Password"
                      ref={register({
                        required: "Please enter current password.",
                        minLength: {
                          value: 6,
                          message:
                            "Current Password should contain at least 6 characters.",
                        },
                        maxLength: {
                          value: 50,
                          message:
                            "Current password should not exceed 50 characters.",
                        },
                        pattern: {
                          value:
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#/$%/^&/*])(?=.{8,})/,
                          message:
                            "Password should contain at least 1 Uppercase,1 Lowercase,1 Numeric and 1 special character.",
                        },
                      })}
                    />
                    {errors.password && errors.password.message && (
                      <span role="alert" className="color">
                        {errors.password.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </div>
              <div className="col-md-6 createaccount-form ">
                <div className="account-form-content">
                  <Form.Group className="mb-3" controlId="don">
                    <Form.Label>dob Of Birth*</Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Enter Your Date Of Birth"
                      name="dob"
                      ref={register({
                        required: true,
                      })}
                    />
                    {errors.dob && errors.dob.type === "required" && (
                      <span role="alert" className="color">
                        Please enter your date of birth.
                      </span>
                    )}
                  </Form.Group>
                  {["radio"].map((type) => (
                    <div
                      key={`default-${type}`}
                      className="w-100 d-inline-flex justify-content-between mb-3"
                    >
                      <Form.Check // prettier-ignore
                        className="d-inline-block"
                        type={type}
                        name={"gender"}
                        label={`Male`}
                        id={`male`}
                        checked={true}
                        value="Male"
                        onChange={handleChange}
                      />

                      <Form.Check
                        className="d-inline-block"
                        type={type}
                        name={"gender"}
                        label={`Female`}
                        id={`female`}
                        value="Female"
                        onChange={handleChange}
                      />
                      <Form.Check
                        className="d-inline-block"
                        type={type}
                        name={"gender"}
                        label={`Other`}
                        id={`other`}
                        value="Other"
                        onChange={handleChange}
                      />
                    </div>
                  ))}
                  {["checkbox"].map((type) => (
                    <div key={`default-${type}`} className="mb-3">
                      <Form.Check // prettier-ignore
                        type={type}
                        id={`default-${type}`}
                        label={`By clicking on Sign up, you agree to Terms Of Service
                          and Privacy Policy`}
                        name="service"
                        ref={register({
                          required: true,
                        })}
                      />
                      {errors.service && errors.service.type === "required" && (
                        <span role="alert" className="color">
                          Please accept Terms Of Service and Privacy Policy.
                        </span>
                      )}
                    </div>
                  ))}
                  <Button
                    className="btn main-btn"
                    variant="primary"
                    type="submit"
                  >
                    Submit
                  </Button>
                  <div className="donthaveaccount mt-5">
                    <p className="mb-0">
                      Already have an account? <Link to="/login">Login</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
