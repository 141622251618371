import React, { Fragment } from "react";
import { LOGIN_BG } from "../../assets/images";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../actions/userActions";
import { useHistory } from "react-router-dom";

const Login = () => {
  const { register, errors, handleSubmit } = useForm();
  const { push } = useHistory();
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(forgotPassword(data, push));
  };

  return (
    <Fragment>
      <div className="create-account-section">
        <img className="create-account-bg" src={LOGIN_BG} alt="login_bg" />
        <div className="createaccount-content row gx-0">
          <div className="col-md-6 createaccount-form order-md-last">
            <Form
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
              className="d-flex justify-content-between flex-column"
            >
              <div className="account-form-content">
                <h2>Forgot Your Password?</h2>
                <p className="info">
                  Enter the email address you used when you signed up and we'll
                  help you out.
                </p>
                <Form.Group className="mb-3" controlId="formBasicMobileNumber">
                  <Form.Label>Email Address*</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Your Email ID"
                    name="email"
                    ref={register({
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      },
                      maxLength: 50,
                    })}
                  />
                  {errors.email && errors.email.type === "required" && (
                    <span role="alert" className="color">
                      Please enter your email address.
                    </span>
                  )}
                  {errors.email && errors.email.type === "pattern" && (
                    <span role="alert" className="color">
                      Invalid email address.
                    </span>
                  )}
                  {errors.email && errors.email.type === "maxLength" && (
                    <span role="alert" className="color">
                      Your email should not exceed 50 characters.
                    </span>
                  )}
                </Form.Group>
                <Button
                  className="btn main-btn"
                  variant="primary"
                  type="submit"
                >
                  Submit
                </Button>
              </div>

              <div className="donthaveaccount mt-5">
                <p className="mb-0">
                  Already have an account? <Link to="/login">Login</Link>
                </p>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
